//import './modules/sliders';

//style
import '../style/main.scss';

//svg sprite
//import '../static/img/svg/icon-search.svg';


window.addEventListener('load', function () {
    let roundedPhrase = document.querySelector('.hero_phrase'),
    firstWord = document.querySelector('.hero_word--first'),
    secondWord = document.querySelector('.hero_word--second'),
    partnersItems = document.querySelectorAll('.partners_item'),
    partnersItemsTitle = document.querySelectorAll('.partners_item--visible'),
    partnersSwitcher = document.querySelectorAll('.partners_switcher');

    //стилизация фразы в блоке hero на Главной

    if (roundedPhrase) {
        function getOffsetTop(el) {
                const rect = el.getBoundingClientRect();
                return rect;
            };

            function addClassRounded () {
                if (getOffsetTop(roundedPhrase).height === getOffsetTop(firstWord).height) {
                    roundedPhrase.classList.add("hero_text--rounded-colored");
                } else {
                    firstWord.classList.add("hero_text--rounded-colored");
                    secondWord.classList.add("hero_text--rounded-colored");
                }
            };
            
            addClassRounded();
    }
    

    //открытие блока с информацией о партнёре
    if (partnersItems) {
        partnersItemsTitle.forEach (item => {
            item.addEventListener('click', (e) => {
    
                let itemParent = item.parentNode;
                let infoBlock = itemParent.querySelector('.partners_item--switchable');
    
                if (infoBlock.classList.contains('partners_item--opened')) {
                    infoBlock.classList.remove('partners_item--opened');
                    item.querySelector('.partners_switcher').classList.remove('partners_swicher--open');
    
                } else {
                    partnersItems.forEach (item => {
                    item.querySelector('.partners_item--switchable').classList.remove('partners_item--opened');
                    item.querySelector('.partners_switcher').classList.remove('partners_swicher--open');
                    })
                
                    infoBlock.classList.add('partners_item--opened');
                    item.querySelector('.partners_switcher').classList.add('partners_swicher--open');
                }               
            })
        })
    }

})


